<template>
    <div class="page-wrapper member-logins-wrap">
        <div class="container-fluid">
            <breadcrumb :items="breadcrumbItems" />
            <div class="page-title">
                <h1>Member Logins</h1>
            </div>
            
            <div class="new-table-card">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Member Username</th>
                                <th>Last Login</th>
                                <th>Device</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span class="username">Aidan.oneal@gmail.com</span>
                                </td>
                                <td>
                                    <span class="last-login">Not Available</span>
                                </td>
                                <td>
                                    <div class="action-icons">
                                        <a href="#" v-b-tooltip.hover title="View Details"><i class="fab fa-apple"></i></a>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span class="username">Aidan.oneal@gmail.com</span>
                                </td>
                                <td>
                                    <span class="last-login">Not Available</span>
                                </td>
                                <td>
                                    <div class="action-icons">
                                        <a href="#" v-b-tooltip.hover title="View Details"><i class="fab fa-android"></i></a>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="table-pagination mt-3">
                    <ul>
                        <li><a href="#" class="active-table">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#" class="next-highlight">Next</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MemberLogins',
    data() {
		return {
			breadcrumbItems: [{ title: "Member Logins", active: true }],
		};
	},
}
</script>
<style src="../../assets/css/loginsnotlogins.css"></style>
<style>
.member-logins-wrap .action-icons a {
    margin: 0px;
}
</style>
