var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper member-logins-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _vm._m(0), _c('div', {
    staticClass: "new-table-card"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(1), _c('tbody', [_c('tr', [_vm._m(2), _vm._m(3), _c('td', [_c('div', {
    staticClass: "action-icons"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "href": "#",
      "title": "View Details"
    }
  }, [_c('i', {
    staticClass: "fab fa-apple"
  })])])])]), _c('tr', [_vm._m(4), _vm._m(5), _c('td', [_c('div', {
    staticClass: "action-icons"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "href": "#",
      "title": "View Details"
    }
  }, [_c('i', {
    staticClass: "fab fa-android"
  })])])])])])])]), _vm._m(6)])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Member Logins")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Member Username")]), _c('th', [_vm._v("Last Login")]), _c('th', [_vm._v("Device")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('span', {
    staticClass: "username"
  }, [_vm._v("Aidan.oneal@gmail.com")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('span', {
    staticClass: "last-login"
  }, [_vm._v("Not Available")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('span', {
    staticClass: "username"
  }, [_vm._v("Aidan.oneal@gmail.com")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('span', {
    staticClass: "last-login"
  }, [_vm._v("Not Available")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-pagination mt-3"
  }, [_c('ul', [_c('li', [_c('a', {
    staticClass: "active-table",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("1")])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("2")])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("3")])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("4")])]), _c('li', [_c('a', {
    staticClass: "next-highlight",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Next")])])])]);
}]

export { render, staticRenderFns }